import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/userViews' },
  {
    path: '/userViews',
    name: 'index',
    component: () => import('../views/userViews.vue')
  },
  {
    path: '/waterloggingPoint',
    component: () => import('../views/waterloggingPoint.vue')
  },
  {
    path: '/manuscriptStatistics',
    component: () => import('../views/manuscriptStatistics.vue')
  },
  {
    path: '/electricityAnalysis',
    component: () => import('../views/electricityAnalysis.vue')
  },
  {
    path: '/consumptionAnalysis',
    component: () => import('../views/consumptionAnalysis.vue')
  },
  {
    path: '/pic',
    component: () => import('../views/pic.vue')
  },
  {
    path: '/pic1',
    component: () => import('../views/pic1.vue')
  },
  {
    path: '/index',
    component: () => import('../views/index.vue')
  }
]
const router = new VueRouter({
  routes
})


export default router